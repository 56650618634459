import { z } from "zod";

const EnvVarsSchema = z
  .object({
    ENVIRONMENT: z.enum(["local", "development", "production"]),
    SANITY_DATASET: z.string(),

    API_BASE_URL: z.string().url(),
    BASE_ASSET_URL: z.string().url(),
    BASE_DATA_URL: z.string().url(),
    SPA_SITE_URL: z.string().url(),
    DEADLOCK_BASE_URL: z.string().url(),
    DEADLOCK_SERVER_TOKEN: z.string(),
    GOOGLE_TAG_MANAGER_KEY: z.string(),
    RECAPTCHA_SITE_KEY: z.string(),
    RECAPTCHA_SECRET_KEY: z.string(),
    FLAGSMITH_ENVIRONMENT_ID: z.string(),
    SENTRY_DSN: z.string().optional(),
    REACT_QUERY_DEVTOOLS_BUTTON_POSITION: z.enum(["top-left", "bottom-left", "top-right", "bottom-right"]).optional(),
    PARTYKIT_URL: z.string().url(),
    CROSS_SERVER_TOKEN: z.string(),
  })
  .transform((data) => {
    // If this is called from the frontend, we should redact the DEADLOCK_SERVER_TOKEN and RECAPTCHA_SECRET_KEY
    return {
      ...data,
      DEADLOCK_SERVER_TOKEN: typeof window !== "undefined" && "ENV" in window ? "REDACTED" : data.DEADLOCK_SERVER_TOKEN,
      RECAPTCHA_SECRET_KEY: typeof window !== "undefined" && "ENV" in window ? "REDACTED" : data.RECAPTCHA_SECRET_KEY,
    };
  });

export type AppConfig = z.infer<typeof EnvVarsSchema>;

export const getConfig = () => {
  const envVars =
    typeof window !== "undefined" && "ENV" in window
      ? EnvVarsSchema.safeParse(window.ENV)
      : EnvVarsSchema.safeParse(process.env);

  if (!envVars.success) {
    // eslint-disable-next-line no-console
    console.error("There is a problem with your environment variables");
    console.error(envVars.error.message);
    throw new Error("There is a problem with your environment variables: " + envVars.error.message, {
      cause: envVars.error,
    });
  }

  return envVars.data;
};
